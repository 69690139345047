<template>
    <div>
      <h1>Welcome to the Shopify Page!</h1>
      <p>This is the content of the Shopify page.</p>
  
      <!-- Using the FormFieldset component -->
      <FormFieldset>
        <p>This is inside the fieldset component.</p>
      </FormFieldset>
    </div>
  </template>
  
  <script>
  // Import the FormFieldset component
  import FormFieldset from "@ergonode/ui/src/components/Form/FormFieldset.vue";
  
  export default {
    name: 'ShopifyPage',
    components: {
      FormFieldset, // Register the FormFieldset component
    },
  };
  </script>
  
  <style scoped>
  /* Add any specific styles for your Shopify page here */
  </style>
  