var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Welcome to the Shopify Page!")]),
      _vm._v(" "),
      _c("p", [_vm._v("This is the content of the Shopify page.")]),
      _vm._v(" "),
      _c("FormFieldset", [
        _c("p", [_vm._v("This is inside the fieldset component.")])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }